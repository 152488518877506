import axios from 'axios'
import { Loading, Message } from "element-ui";
import router from '../router';

let loadingInstance, timer;


const service = axios.create({
  // 云校平台
  // baseURL: 'http://192.168.1.117:9101/',
  //   baseURL: 'http://localhost:9101/',
  //   baseURL: 'http://47.93.6.203:9101/',
  // k-12平台
  baseURL: 'https://api.01ycloud.com:9101/',
  timeout: 60000,
})

service.interceptors.request.use(
  config => {
      // config.headers['X-Token'] = getToken();
      config.headers['X-Front'] = "front"
    // loadingInstance = Loading.service({
    //   fullscreen: true,
    //   text: 'Loading',
    //   spinner: 'el-icon-loading',
    //   background: 'rgba(0, 0, 0, 0.7)'
    // });
    // console.log(loadingInstance)
    // config.headers.Authorization = window.localStorage.getItem('token')
    return config;
  },
  error => {
    // do something with request error
    // console.log(error); // for debug
    return Promise.reject(error);
  }

);
service.interceptors.response.use(
  response => {
    const res = response.data;
    // if (loadingInstance) {
    //   loadingInstance.close();
    // }
    // console.log(res, "数据")
    if (res.code !== 200) {
      if (res.code == -1) {
        return Promise.reject();
      }
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 2 * 1000
      });
      if (res.code == 300) {
        router.push({
          path: '/notice'
        })
        return Promise.reject(new Error(res.message || '登录拒绝'));
      }
      return Promise.reject(new Error(res.message || '登录拒绝'));
    } else {

      return res;
    }

  },
  error => {
    // console.log("err" + error); // for debug
    // if (loadingInstance) {
    //   loadingInstance.close();
    // }
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);
export default service
