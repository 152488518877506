

const state = {
    info: ""
};

const mutations = {
   
};

const actions = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};