import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from "store";
import { getHttp } from "../api/user";
Vue.use(VueRouter);

function load(path, type = 0) {
  const dir = type ? 'components' : 'views';
  return () => import(`@/${dir}/${path}.vue`);
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
    return originalReplace.call(this, location).catch(err => err)
}

const Router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'layout',
      component: load('layout'),
      redirect: 'index',
      children: [
        {
          path: '/index',
          name: 'Home',
          component: load('Home'),
        },

          // 单科
        {
          path: '/course',
          name: 'course',
          component: load('course/course'),

        },
      {
        path: '/video',
        name: 'video',
        component: load('course/video'),
      },

      // //课程播放页
      // {
      //     path: '/detailVideo',
      //     name: 'detailVideo',
      //     component: load('course/detailVideo'),
      // },
          // 单科列表页
        {
            path: '/list',
            name: 'list',
            component: load('course/list'),
        },
          //课程详情页
        {
            path: '/detail',
            name: 'detail',
            component: load('course/detail'),
        },
        // 系列科
        {
            path: '/series',
            name: 'series',
            component: load('series/series'),

        },
        // 系列列表页
        {
            path: '/serList',
            name: 'serList',
            component: load('series/serList'),
        },
          //系列课课程列表

      {
          path: '/seriesDetail',
          name: 'seriesDetail',
          component: load('series/seriesDetail'),
      },

        {
          path: '/contact',
          name: 'contact',
          component: load('contact/contact'),
        },
          //文章
        {
            path: '/article',
            name: 'article',
            component: load('article/article'),
            // children: [
            //     {
            //       path: '/detail',
            //       name: 'articleDetail',
            //       component: load('article/articleDetail'),
            //     },
            // ]
        },
        {
            path: '/articleDetail',
            name: 'articleDetail',
            component: load('article/articleDetail'),
        },

        //图书资料
        {
            path: '/book',
            name: 'book',
            component: load('book/book'),
        },
        {
            path: '/bookDetail',
            name: 'bookDetail',
            component: load('book/bookDetail'),
        },
          // 考试相关信息
        {
          path: '/info',
          name: 'info',
          component: load('info'),
        },
        // {
        //   path: '/exam',
        //   name: 'exam',
        //   component: load('tiku/tiku'),
        //   children: [
        //
        //     {
        //       path: '/zhangjie',
        //       name: 'zhangjie',
        //       component: load('tiku/child/zhangjie'),
        //     },
        //     {
        //       path: '/moni',
        //       name: 'moni',
        //       component: load('tiku/child/moni'),
        //     },
        //     {
        //       path: '/history',
        //       name: 'history',
        //       component: load('tiku/child/history'),
        //     },
        //     {
        //       path: '/yati',
        //       name: 'yati',
        //       component: load('tiku/child/yati'),
        //     },
        //   ]
        // },
        // {
        //     path: '/examGuide',
        //     name: 'examGuide',
        //     component: load('tiku/examGuide'),
        // },
      {
          path: '/live',
          name: 'live',
          component: load('live/live'),
      },
      {
          path: '/liveVideo',
          name: 'liveVideo',
          component: load('live/liveVideo'),
      },
      {
          path: '/examGuide',
          name: 'examGuide',
          component: load('tiku/examGuide'),
      },
          {
              path: '/livePay',
              name: 'livePay',
              component: load('live/livePay'),
          },
        {
            path: '/examDetail',
            name: 'examDetail',
            component: load('tiku/examDetail'),
        },
        {
            path: '/examChoice',
            name: 'examChoice',
            component: load('tiku/examChoice'),
        },
        {
            path: '/examPay',
            name: 'examPay',
            component: load('tiku/examPay'),
        },
      {
        path: '/exam',
        name: 'exam',
        component: load('exam/exam'),
      },
      {
          path: '/examRecords',
          name: 'examRecords',
          component: load('exam/examRecords'),
      },
      {
          path: '/practice',
          name: 'practice',
          component: load('exam/practice'),
      },
      {
        path: '/examQuery',
        name: 'examQuery',
        component: load('exam/examQuery'),
      },
      {
        path: '/analysis',
        name: 'analysis',
        component: load('exam/analysis'),
      },
      {
        path: '/footerLink',
        name: 'footerLink',
        component: load('footerLink'),
      },
      {
        path: '/download',
        name: 'download',
        component: load('download'),
      },
      ],
    },
    {
      path: '/',
      name: 'layout2',
      component: load('layout2'),
      children: [
      //课程播放页
        {
            path: '/detailVideo',
            name: 'detailVideo',
            component: load('course/detailVideo'),
        },
      {
          path: '/personal',
          name: 'personal',
          component: load('personal/personal'),
      },

      ],
      },
    // {
    //   path: '/',
    //   name: 'layout2',
    //   component: load('layout2'),
    //   children: [
    //     {
    //       path: '/contact',
    //       name: 'contact',
    //       component: load('contact/contact'),
    //     },
    //     {
    //       path: '/live',
    //       name: 'Live',
    //       component: load('live/live'),
    //     }, {
    //       path: '/detail',
    //       name: 'detail',
    //       component: load('live/detail'),
    //     }, {
    //       path: '/detail2',
    //       name: 'detail2',
    //       component: load('live/detail2'),
    //     }, {
    //           path: 'synthe' ,
    //           name: 'synthe',
    //           component: load('live/synthesizeDetail'),
    //       },
    //     {
    //       path: '/tiku',
    //       name: 'tiku',
    //       // redirect: '/zhangjie',
    //       component: load('tiku/tiku'),
    //       // children: [
    //       //   {
    //       //     path: '/zhangjie',
    //       //     name: 'zhangjie',
    //       //     component: load('tiku/child/zhangjie'),
    //       //   },
    //       //   {
    //       //     path: '/moni',
    //       //     name: 'moni',
    //       //     component: load('tiku/child/moni'),
    //       //   },
    //       //   {
    //       //     path: '/history',
    //       //     name: 'history',
    //       //     component: load('tiku/child/history'),
    //       //   },
    //       //   {
    //       //     path: '/yati',
    //       //     name: 'yati',
    //       //     component: load('tiku/child/yati'),
    //       //   },
    //       // ]
    //     },
    //     {
    //       path: '/course',
    //       name: 'course',
    //       component: load('course/course'),
    //       meta: {
    //         keepAlive: false
    //       }
    //     },
    //
    //     {
    //       path: '/personal',
    //       name: 'personal',
    //       component: load('personal/personal'),
    //     },
    //     {
    //       path: '/establish',
    //       name: 'establish',
    //       component: load('order/establish'),
    //     }, {
    //       path: '/payment',
    //       name: 'payment',
    //       component: load('order/payment'),
    //     }
    //   ],
    // },
    {
      path: '/login',
      name: 'login',
      component: load('login/login'),
    },
    // {
    //   path: '/notice',
    //   name: 'notice',
    //   component: load('notice/notice'),
    // },
    // {
    //   path: '/video',
    //   name: 'video',
    //   component: load('course/video'),
    // },
    // {
    //   path: '/video2',
    //   name: 'video2',
    //   component: load('live/video2'),
    // },
    // {
    //   path: '/exam',
    //   name: 'exam',
    //   component: load('exam/exam'),
    // },
    // {
    //   path: '/exam',
    //   name: 'exam1',
    //   component: load('exam/exam1'),
    // },
    // {
    //   path: '/analysis',
    //   name: 'analysis',
    //   component: load('exam/analysis'),
    // },
    // {
    //   path: '/footerLink',
    //   name: 'footerLink',
    //   component: load('footerLink'),
    // },
    // {
    //   path: '/download',
    //   name: 'download',
    //   component: load('download'),
    // },
  ],
});

function except(modules, path) {
  let inPath = false;
  modules.map(n => {
    if (path.split('/')[1] == n) inPath = true;
  });

  return inPath;
}

// import Router from 'vue-router'




Router.beforeEach((to, from, next) => {
  // if (to.path == "/course") {
  //   if (from.path == "/" && to.path == "/course") {
  //     console.log(11111);
  //     to.meta.keepAlive = false
  //   } else if (from.path == "/detail" && to.path == "/course") {
  //     //   console.log(to.meta.keepAlive);
  //     to.meta.keepAlive = true
  //     // }
  //     // else if (from.path == "/course") {
  //     //   from.meta.keepAlive = false
  //     // }
  //   }
  // } else {
  //   to.meta.keepAlive = false
  // }
  window.scrollTo(0, 0);
  // let common = ['login']
    let host = document.domain
    // let host = "www.51xlw.cn"  //"lyy.01ycloud.com"//window.location.host
    // window.localStorage.setItem('siteId', 1)
    // window.localStorage.setItem('',)
    // next()
  getHttp({
    domain: host
  }).then((res) => {
    console.log("站点信息",res);
    if (res.code == 200) {
      window.localStorage.setItem('siteId', res.data.id)
      window.localStorage.setItem('siteLog', res.data.logo)
        // window.localStorage.setItem('siteInfo', res.data)
        window.localStorage.setItem('qrCode', res.data.qrCode)
        console.log("code信息",res.data.qrCode)
        window.localStorage.setItem('android', res.data.android)
        window.localStorage.setItem('icp', res.data.icp)
        window.localStorage.setItem('siteName', res.data.name)
    }
    next();
  });
});

export default Router;
