import request from '@/utils/request';
// 公共域名
export const getHttp = (data) => request({
  url: '/api/site/getSiteId',
  method: 'POST',
  data
});
// 登录
export const goLogin = (front,siteId,username, password) => {
    return request.post('/login', {
        front:front,
        siteId:siteId,
        username: username,
        password: password
    })
}
// 注册
export const register = (data) => request({
  url: '/api/login/register',
  method: 'POST',
  data
});
// 发送验证码
export const sendCode = (data) => request({
  url: '/api/login/send_verification_code',
  method: 'POST',
  data
});
// 忘记密码
export const ForgetPassd = (data) => request({
  url: '/api/login/forget_pass',
  method: 'POST',
  data
});
// 重置密码
export const setNewPsd = (data) => request({
  url: '/api/login/reset_pass',
  method: 'POST',
  data
});
// 动态logo
export const getLogo = (data) => request({
  url: '/api/login/logo',
  method: 'POST',
  data
});
// 动态logo
export const getUserType = () => request({
    url: 'api/login/user_type',
    method: 'POST',

});
