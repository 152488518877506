import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import './utils/rem'
import 'lib-flexible'
// import './utils/flexible'
// import style (>= Swiper 6.x)

import 'swiper/swiper-bundle.css'
// import BaiduMap from 'vue-baidu-map';
// Vue.use(BaiduMap, {
//   ak: '9WfEoflEWwE7RKC8pkEwy5NK6F930Hdv'
// })
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
// 公共样式
import './assets/css/base.less';
// // 按需导入element-ui
import "./api/element.js";
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.css'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element)
// echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 动态修改meta搜索引擎
// import Meta from 'vue-meta'
// Vue.use(Meta)

// 引入 vue-meta-info
import MetaInfo from 'vue-meta-info'
// 注册 vue-meta-info
Vue.use(MetaInfo)

// 生成二维码
import VueQriously from 'vue-qriously'
Vue.use(VueQriously)

Vue.config.productionTip = false

Vue.filter('dateFormat', function (originVal) {
  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  return `${y}.${m}.${d}`
  // const hh = (dt.getHours() + '').padStart(2, '0')
  // const mm = (dt.getMinutes() + '').padStart(2, '0')
  // const ss = (dt.getSeconds() + '').padStart(2, '0')
  // return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
