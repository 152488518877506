import request from '@/utils/request';
import { goLogin } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
// import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  // token: getToken(),
  // name: '',
  // avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80',
  // introduction: '',
  // roles: []
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  // SET_TOKEN: (state, token) => {
  //   state.token = token;
  // },
  // SET_INTRODUCTION: (state, introduction) => {
  //   state.introduction = introduction;
  // },
  // SET_NAME: (state, name) => {
  //   state.name = name;
  // },
  // SET_AVATAR: (state, avatar) => {
  //   state.avatar = avatar;
  // },
  // SET_ROLES: (state, roles) => {
  //   state.roles = roles;
  // }
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const {
      username,
      password
    } = userInfo;
    // console.log(password, username,)
    return new Promise((resolve, reject) => {
      goLogin({
        username: username.trim(),
        password: password
      }).then((
        data
      ) => {
        // console.log(data)
        commit('SET_TOKEN', data.token);
        setToken(data.token);
        resolve();
      }).catch(error => {
        // console.log(error, 'error');
        reject(error);
      });
    });
  },

  // // get user info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {});
  // },

  // // user logout
  // logout({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     goToLogout().then(({ msg }) => {
  //       commit('SET_TOKEN', '');
  //       removeToken();
  //       resolve(msg);
  //     }).catch((error) => {
  //       reject(error);
  //     });
  //   });
  // },

  // // remove token
  // resetToken({ commit }) {
  //   return new Promise(resolve => {
  //     commit('SET_TOKEN', '');
  //     commit('SET_ROLES', []);
  //     removeToken();
  //     resolve();
  //   });
  // }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
