
import request from '@/utils/request'



export const banner = (data) => request({
  url: '/api/site-banner/bannerList',
  method: 'POST',
  data
});
// 顶部二级分类
export const getCateGory = (data) => request({
  url: '/api/culum-category/listsCoverCulum',
  method: 'POST',
  data
});


// 获取类目

export const siteCulumCate = (data) => request({
    url: '/api/site-culum/siteCateCulums',
    method: 'POST',
    data
});

// 获取三级类目
export const siteCulumTCate = (data) => request({
    url: '/api/culum-category/culumTCate',
    method: 'POST',
    data
});


// 最近直播课
export const broadcast = (data) => request({
  url: '/api/site-culum-live/lastLive',
  method: 'POST',
  data
});


// 行业直播
export const broadcastCate = (data) => request({
    url: '/api/site-culum-live/findCateLive',
    method: 'POST',
    data
});




// 课程推荐
export const getList = (data) => request({
    url: '/api/culum/getList',
    method: 'POST',
    data
});

// 首页系列课
export const indexSeries = (data) => request({
  url: '/api/index/series',
  method: 'POST',
  data
});
// 首页楼层
export const indexFloor = (data) => request({
  url: '/api/site-floor/listPc',
  method: 'POST',
  data
});

// 二级页面楼层
export const indexTwoFloor = (data) => request({
    url: '/api/site-floor/frontTwoMode',
    method: 'POST',
    data
});


// 图书数据
export const indexBook = (data) => request({
    url: '/api/file/frontList',
    method: 'POST',
    data
});

// 行业图书数据
export const cateBook = (data) => request({
    url: '/api/file/frontCateList',
    method: 'POST',
    data
});


// 右侧边栏
export const rightSide = (data) => request({
  url: '/api/index/index_right',
  method: 'POST',
  data
});
// 底部
export const footer = (data) => request({
  url: '/api/index/index_bottom',
  method: 'POST',
  data
});
// 联系我们
export const about = (data) => request({
  url: '/api/site/getSiteById',
  method: 'POST',
  data
});


//文章资讯 /api/article/listPagesMobile
export const article = (data) => request({
    url: '/api/article/listPagesMobile',
    method: 'POST',
    data
});


// 获取关键词描述
export const keyWord = (data) => request({
  url: '/api/index/index_top',
  method: 'POST',
  data
});
// 左侧联动
export const leftSide = (data) => request({
  url: '/api/site-floor/listLeft',
  method: 'POST',
  data
});
// 获取兴趣推荐
export const getswitch = (data) => request({
  url: '/api/index/switch_category',
  method: 'POST',
  data
});
// 提交兴趣标签
export const addSwitch = (data) => request({
  url: '/api/index/add_switch_category',
  method: 'POST',
  data
});

// 底部新手指导
export const footerLink = (data) => request({
  url: '/api/site-bottom/getById',
  method: 'POST',
  data
});


// 考试综合信息
export const getByCateId = (data) => request({
    url: '/api/site-register-info/getByCateId',
    method: 'POST',
    data
});




// 注册短信
export const getSmsByPhone = (data) => request({
    url: '/api/system/user/sms',
    method: 'POST',
    data
});

// 注册用户
export const siteUserAdd = (data) => request({
    url: '/api/site/user/add',
    method: 'POST',
    data
});
